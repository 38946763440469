import request from '@/utils/request';
import { PagedCollection } from "@/types/hydra";
import { Contract, ContractApprovingList, ContractStageApprover } from "@/types/Contract";
import { MIMETypes } from '@/enums';

const baseUri = '/contracts';

export const getContracts = (params: object): Promise<PagedCollection<Contract>> =>
    request({
        url: baseUri,
        method: 'GET',
        params
    });

export const getContract = (id: string, params?: object): Promise<Contract> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'GET',
        params,
    });

export const createContract = (data: object): Promise<Contract> =>
    request({
        url: baseUri,
        method: 'POST',
        data
    });

export const deleteContract = (id: string): Promise<void> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'DELETE'
    });

export const updateContract = (id: string, data: object): Promise<Contract> =>
    request({
        url: `${baseUri}/${id}`,
        method: 'PATCH',
        data
    });

export const getPresets = (params: object): Promise<any> =>
    request({
        url: `${baseUri}/presets`,
        method: 'GET',
        params,
    });

export const getApprovers = (id: string): Promise<PagedCollection<ContractStageApprover>> =>
    request({
        url: `${baseUri}/${id}/approvers`,
        method: 'GET',
    });

export const startApproving = (id: string, data: object) =>
    request({
        url: `${baseUri}/${id}/start_approving`,
        method: 'POST',
        data,
    });

export const deadlineContract = (id: string, data: object) =>
    request({
        url: `${baseUri}/${id}/deadline`,
        method: 'POST',
        data,
    });

export const approve = (id: string, data: object) =>
    request({
        url: `${baseUri}/${id}/approve`,
        method: 'POST',
        data,
    });

export const decline = (id: string, data: object) =>
    request({
        url: `${baseUri}/${id}/decline`,
        method: 'POST',
        data,
    });

export const reject = (id: string, data: object) =>
    request({
        url: `${baseUri}/${id}/reject`,
        method: 'POST',
        data,
    });

export const archive = (id: string, data: object): Promise<Contract> =>
    request({
        url: `${baseUri}/${id}/archive`,
        method: 'POST',
        data
    });

export const getContractApprovingList = (id: string): Promise<ContractApprovingList> =>
    request({
        url: `${baseUri}/${id}/approving_list`,
        method: 'GET'
    });

export const getContractRejectTransitions = (id: string): Promise<void> =>
    request({
        url: `${baseUri}/${id}/reject_transitions`,
        method: 'GET'
    });

export const exportContractsToXlsx = (params: object) =>
    request({
        url: `${baseUri}/export`,
        method: 'GET',
        params,
        responseType: 'arraybuffer',
        headers: {
            accept: MIMETypes.XLSX,
        },
    });
