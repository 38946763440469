import DepartmentAPI from '@/api/staff/department';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchList (params) {
        return DepartmentAPI.findAll(params);
    },
    doFetchSingle (id, params) {
        return DepartmentAPI.find(id, params);
    },
    doCreate (data) {
        return DepartmentAPI.create(data);
    },
    doUpdate (id, data, params) {
        return DepartmentAPI.update(id, data, params);
    },
    doDelete (id) {
        return DepartmentAPI.delete(id);
    },
});
