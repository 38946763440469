<template>
    <div
        :class="{ 'is-active-label': labelActive }"
        class="table-filter"
    >
        <div class="table-filter__slot">
            <el-popover
                ref="popover"
                placement="bottom-start"
                popper-class="interval-selector__popover"
                trigger="click"
                @show="handlePopoverShow"
                @hide="handlePopoverHide"
            >
                <div slot="reference">
                    <div
                        v-if="label"
                        class="table-filter__label"
                    >
                        <iconify-icon
                            slot="reference"
                            icon="fa6-solid:filter"
                            class="table-filter__icon"
                        />
                        {{ label }}
                    </div>
                    <div class="table-filter__tag-wrapper">
                        <el-tag
                            v-if="formattedValue"
                            closable
                            size="small"
                            disable-transitions
                            @close="handleTagClose"
                        >
                            {{ formattedValue }}
                        </el-tag>
                    </div>
                </div>

                <div class="table-filter__range">
                    <ui-masked-input
                        ref="fromControl"
                        v-model="fromValue"
                        :mask="Number"
                        :min="minValue"
                        :scale="scale"
                        :signed="signed"
                        thousands-separator=" "
                        radix=","
                        clearable
                        :unmask="true"
                    />
                    -
                    <ui-masked-input
                        v-model="toValue"
                        :mask="Number"
                        :min="minValue"
                        :scale="scale"
                        :signed="signed"
                        thousands-separator=" "
                        radix=","
                        clearable
                        :unmask="true"
                    />
                </div>
            </el-popover>
        </div>
    </div>
</template>

<script>
import { isEqual } from 'lodash';
import FormatMixin from '@/mixins/format';

export default {
    name: 'UiTableFilterNumberRange',

    mixins: [FormatMixin],

    props: {
        value: {
            type: Array,
            default: () => [],
        },

        label: {
            type: String,
            default: '',
        },

        scale: {
            type: Number,
            default: 2,
        },

        signed: {
            type: Boolean,
            default: false,
        },

        minValue: {
            type: Number,
            default: 0,
        },
    },

    data () {
        return {
            focus: false,
            fromValue: String(this.value[0] ?? ''),
            toValue: String(this.value[1] ?? ''),
        };
    },

    computed: {
        currentValue: {
            get () {
                return this.value;
            },

            set (value) {
                if (!isEqual(value, this.value)) {
                    this.$emit('input', value);
                }
            },
        },

        labelActive () {
            if (this.currentValue.length > 0) {
                return true;
            }

            return this.focus;
        },

        formattedValue () {
            if (!this.currentValue.length) {
                return '';
            }

            if (this.currentValue[0] === this.currentValue[1]) {
                return this.formatNumber(this.currentValue[0], this.scale);
            }

            return this.formatNumber(this.currentValue[0], this.scale) +
                ' - ' +
                this.formatNumber(this.currentValue[1], this.scale);
        },
    },

    watch: {
        value () {
            this.fromValue = typeof this.value[0] !== 'undefined' ? String(this.value[0]) : '';
            this.toValue = typeof this.value[1] !== 'undefined' ? String(this.value[1]) : '';
        },
    },

    methods: {
        apply () {
            if (this.fromValue && !this.toValue) {
                this.toValue = this.fromValue;
            } else if (this.toValue && !this.fromValue) {
                this.fromValue = this.toValue;
            }

            const value = [];

            if (this.fromValue && this.toValue) {
                const from = Number(this.fromValue.replace(/\s+/g, '').replace(',', '.'));
                const to = Number(this.toValue.replace(/\s+/g, '').replace(',', '.'));
                if (from <= to) {
                    value[0] = from;
                    value[1] = to;
                } else {
                    [this.fromValue, this.toValue] = [this.toValue, this.fromValue];
                    value[0] = to;
                    value[1] = from;
                }
            }

            this.currentValue = value;
        },

        handlePopoverShow () {
            this.$nextTick(() => {
                this.$refs.fromControl.$el.querySelector('input').focus();
            });
        },

        handlePopoverHide () {
            this.apply();
            this.$emit('close');
        },

        handleTagClose () {
            this.fromValue = '';
            this.toValue = '';

            this.apply();
            this.$emit('close');
        },
    },
};
</script>
