import Vue from 'vue';
import Vuex from 'vuex';
import { getMe, updateUser } from '@/api/user';
import eventBus from '@/event-bus';
import { BusinessMemberRoleTypes } from '@/enums';

Vue.use(Vuex);

const modules = {};
const importedModules = import.meta.glob(['./modules/*.js', './modules/*.ts'], { eager: true });
for (const [path, module] of Object.entries(importedModules)) {
    const moduleName = path.replace(/^.+\/(.*)\.\w+$/, '$1');
    modules[moduleName] = module.default;
}

const createEventBusPlugin = eventBus => {
    return store => {
        for (const moduleName of Object.keys(modules)) {
            eventBus.on([`${moduleName}:create`, `${moduleName}:update`], data => {
                const mutationName = `${moduleName}/SET_ENTITY`;
                if (mutationName in store._mutations) {
                    store.commit(mutationName, data);
                }
            });

            eventBus.on(`${moduleName}:delete`, id => {
                const mutationName = `${moduleName}/UNSET_ENTITY`;
                if (mutationName in store._mutations) {
                    store.commit(mutationName, id);
                }
            });
        }

        store.subscribe(mutation => {
            const [moduleName, mutationName] = mutation.type.split('/');
            if (moduleName && mutationName) {
                if (mutationName === 'CREATE_SUCCESS') {
                    eventBus.emit(`${moduleName}:create`, mutation.payload);
                } else if (mutationName === 'UPDATE_SUCCESS') {
                    const prevData = store.getters[`${moduleName}/byId`](mutation.payload.id);
                    eventBus.emit(`${moduleName}:update`, mutation.payload, prevData);
                } else if (mutationName === 'DELETE_SUCCESS') {
                    eventBus.emit(`${moduleName}:delete`, mutation.payload);
                }
            }
        });
    };
};

const store = new Vuex.Store({
    modules,
    state: {
        booted: false,
        me: null,
        meLoading: false,
        meError: null,
        accessDenied: false
    },
    getters: {
        me: state => state.me,
        isBooted: state => state.booted,
        meError: state => state.meError,
        meLoading: state => state.meLoading,
        isAdmin: (state, getters) => (businessId = getters['business/currentId']) => state.me?.adminRoleInBusinesses
            ?.some(item => item.businessId === businessId),
        isOwner: (state, getters) => (businessId = getters['business/currentId']) => state.me?.members?.some(item =>
            item.business.id === businessId &&
            item.role === BusinessMemberRoleTypes.OWNER,
        ),
        currentBusinessGroup: (state, getters, rootState, rootGetters) => {
            if (rootGetters['business/current']) {
                return rootGetters['business/current'].businessGroup;
            }

            return state.me?.primaryBusinessGroup;
        },
        currentBusinessGroupId: (state, getters, rootState, rootGetters) => {
            if (rootGetters['business/current']) {
                return rootGetters['business/current'].businessGroup.id;
            }

            return state.me?.primaryBusinessGroup?.id;
        },
        accessDenied: state => state.accessDenied
    },
    mutations: {
        FETCH_ME_START (state) {
            state.meLoading = true;
            state.meError = null;
        },
        FETCH_ME_SUCCESS (state, value) {
            state.meLoading = false;
            state.me = value;
        },
        FETCH_ME_ERROR (state, error) {
            state.meLoading = false;
            state.me = null;
            state.meError = error;
        },
        SET_BOOTED (state, value) {
            state.booted = value;
        },
        UPDATE_ME_START (state) {
            state.meLoading = true;
            state.meError = null;
        },
        UPDATE_ME_SUCCESS (state, value) {
            state.meLoading = false;
            state.me = value;
        },
        UPDATE_ME_ERROR (state, error) {
            state.meLoading = false;
            state.meError = error;
        },
        RESET_ME (state) {
            state.me = null;
            state.meError = null;
            state.meLoading = false;
        },
    },
    actions: {
        async fetchMe ({ commit }, { params = { with: ['members', 'adminRoleInBusinesses'] } } = {}) {
            commit('FETCH_ME_START');
            try {
                const data = await getMe(params);
                commit('FETCH_ME_SUCCESS', data);
                return data;
            } catch (e) {
                commit('FETCH_ME_ERROR', e);
                return null;
            }
        },

        resetMe ({ commit }) {
            commit('RESET_ME');
        },

        boot ({ commit }) {
            commit('SET_BOOTED', true);
        },

        async updateMe ({ commit, state }, { params = {}, queryParams = { with: ['members', 'adminRoleInBusinesses'] } } = {}) {
            commit('UPDATE_ME_START');
            try {
                const data = await updateUser(state.me.id, params, queryParams);
                commit('UPDATE_ME_SUCCESS', data);
                return data;
            } catch (e) {
                commit('UPDATE_ME_ERROR', e);
                return null;
            }
        },

        setAccessDenied ({ state }, denied) {
            state.accessDenied = denied;
        }
    },
    plugins: [createEventBusPlugin(eventBus)],
});

export default store;
