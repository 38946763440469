import { createReturnParentalLeave, getReturnParentalLeave, updateReturnParentalLeave } from '@/api/staff/personnelDocument';
import { createCrudModule } from '@/utils/vuex-crud';

export default createCrudModule({
    businessDependent: true,
    doFetchSingle (id) {
        return getReturnParentalLeave(id);
    },
    doCreate (data) {
        return createReturnParentalLeave(data);
    },
    doUpdate (id, data) {
        return updateReturnParentalLeave(id, data);
    },
});
